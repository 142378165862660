import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
/* import authModule from '@/store/auth'
import { Component, Vue } from 'vue-property-decorator' */
@Component
export default class Search extends Vue {
  @Prop() private list!: Array<any>

  search = ''
  localList: Array<any> = []

  clearSearch() {
    this.search = ''
  }

  @Watch('search')
  onPropertyChanged(value: string, oldValue: string) {
    //se guarda la lista original de forma local
    if (this.localList.length == 0 || this.localList < this.list)
      this.localList = this.list

    let filterItems: Array<any> = []
    const hash: any = {}

    this.localList.forEach((x) => {
      Object.keys(x).forEach((y) => {
        if (String(x[y]).toLowerCase().includes(this.search.toLowerCase())) {
          filterItems.push(x)
        }
      })
    })

    //se crea un id si el objeto que use el componente no tiene id
    //se eliminan los duplicados
    if (filterItems.length > 0 && !filterItems[0].id)
      filterItems = filterItems.map((o) => ({
        ...o,
        id: Object.values(o).join('|'),
      }))
    filterItems = filterItems.filter((o) =>
      hash[o.id] ? false : (hash[o.id] = true),
    )
    this.$emit('filterItems', filterItems)
  }
}
